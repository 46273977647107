const DAY_MAPPINGS = {
  mon: 'Mán',
  tue: 'Þri',
  wed: 'Mið',
  thu: 'Fim',
  fri: 'Fös',
  sat: 'Lau',
  sun: 'Sun'
}

export const getDayForKey = (key) => {
  try {
    return DAY_MAPPINGS[key]
  } catch (err) {
    return null
  }
}
