import React from 'react';
import './OpeningHours.scss';

import { Field } from 'formik'

import { CheckBoxInput, TimeInput } from '../index';

import AddIcon from './icons/add.svg';
import RemoveIcon from './icons/remove.svg';

const OpeningHours = ({ label, checkBoxName, isCheckedOpen, canEditHours, currOpeningHours, objectKey, remove, push, setFieldValue, fieldArrayName, canAdd }) => {
    return (
        <Field name={fieldArrayName}>
            {({ meta: arrayMeta }) => {
                const isArrayError = arrayMeta?.error && arrayMeta.touched;
                console.log('isArrayError', isArrayError)
                console.log('arrayMeta.error', arrayMeta.error)
                console.log('arrayMeta.touched', arrayMeta.touched)
                return (
                    <div className="opening-hours">
                        {/* Checkbox */}
                        <CheckBoxInput
                            label={label ? label : !isCheckedOpen ? 'Lokað þessa dagssetningu' : ''}
                            name={checkBoxName}
                            value={isCheckedOpen}
                            checked={isCheckedOpen}
                            onChange={(e) => {
                                if (isCheckedOpen) {
                                    remove(1)
                                    remove(0)
                                } else {
                                    push({ from: null, to: null })
                                }
                                setFieldValue(checkBoxName, !isCheckedOpen)
                            }}
                        />
    
                        {/* Each time slot */}
                        <div className="time-slots">
                            {
                                canEditHours && currOpeningHours.map((hours, index) => (
                                    <div key={`${objectKey}-${index}`} className="time-slot">
                                        <Field name={`${fieldArrayName}.${index}.from`} label='Frá'>
                                            {({ field, meta }) => (
                                                <TimeInput
                                                    onValueChange={(val) => {
                                                        setFieldValue(field.name, val)
                                                    }}
                                                    {...field}
                                                    error={(meta.touched && meta.error)}
                                                />       
                                            )}
                                        </Field>
                                        <Field name={`${fieldArrayName}.${index}.to`} label='Til'>
                                            {({ field, meta }) => (
                                                <TimeInput
                                                    onValueChange={(val) => {
                                                        setFieldValue(field.name, val)
                                                    }}
                                                    {...field}
                                                    error={(meta.touched && meta.error)}
                                                />       
                                            )}
                                        </Field>
                                        {index !== 0 && <button type='button' className="remove-button" onClick={() => remove(index)}><img src={RemoveIcon} alt="Fjarlægja opnunartíma" /></button>}
                                    </div>
                                ))
                            }
                        </div>
    
                        {/* Additional timeslots */}
                        {
                            canAdd &&
                            <button
                                type='button'
                                className='secondary'
                                onClick={() => {
                                    if (currOpeningHours.length < 2) {
                                        push({ from: '', to: '' })
                                    }
                                }}
                            >
                                <img src={AddIcon} alt="Bæta við opnunartíma" />
                            </button>
                        }
                    </div>
                )
            }}
        </Field>
    )
}

export default OpeningHours;