import React from 'react';
import {
  Route,
} from "react-router-dom";
import PropTypes from 'prop-types';

export default function PublicRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => (
        children
      )}
    />
  )
}

PublicRoute.propTypes = {
  children: PropTypes.node
}