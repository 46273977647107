import React from 'react';
import './Input.scss';

import RemoveIcon from '../../../assets/icons/remove.svg';

// Formik
import { ErrorMessage } from 'formik'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";


const DateInput = ({ label, name, onChange, value, required, removeButton }) => (
    <label className={`input_item ${removeButton ? 'short' : ''}`}>
        {label} {required ? '*' : ''}
        <ErrorMessage name={name} component='div' className='error' />
        <DatePicker
            selected={value}
            onChange={onChange}
        />
        {
            removeButton &&
            <button type="button" onClick={removeButton} className='remove-button'>
                <img src={RemoveIcon} alt="fjarlægja" />
            </button>
        }
    </label>
)

export default DateInput;