import React from 'react';
import './Select.scss';

// Formik
import { Field, ErrorMessage } from 'formik'


const Select = ({ label, name, required, options, labelKey, placeholder, defaultLabel, defaultValue, ...rest}) => {
    return (
        <label className="select_item">
            {label} {required ? '*' : ''}
            <ErrorMessage name={name} component='div' className='error' />
            <Field as='select' name={name} required={required} {...rest}>
                <option disabled value={defaultValue}>
                    {defaultLabel}
                </option>
                {
                    options.map((option, index) => (
                        <option key={index} value={index}>{option[labelKey]}</option>
                    ))
                }
            </Field>
        </label>
    )
}

export default Select;