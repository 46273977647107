import { useState, useEffect } from 'react'
import { getUser, deleteToken, setTokenAsync } from "../services/token";

function useAuth() {
    const [user, setUser] = useState(null);

    const signin = async (token) => {
        try {
            await setTokenAsync(token);
            let user = getUser();
            setUser(user);
        } catch (err) {
            return err;
        }
    };

    const signout = () => {
        try {
            deleteToken();
        } finally {
            setUser(null)
        }
    };
        
    useEffect(() => {
        setUser(getUser())
    }, []);
    // Return the user object and auth methods
    return {
        user,
        signin,
        signout
    };
}

export default useAuth;
