import React from 'react'
import createHistory from 'history/createBrowserHistory'

// Routing
import { BrowserRouter } from 'react-router-dom'
import routes from './routes'

// UI
import { Header } from './components/ui';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import MetaImage from './assets/images/pupMetaImage.png';

// Apollo
import ApolloProvider from './graphql/apolloProvider'

// Util
import { initGA, initPixel, logPageView } from './utils/Analytics';

initGA();
initPixel();

const history = createHistory()
history.listen(location => {
    logPageView();
});



const App = () => {
    return (
        <BrowserRouter>
            <Helmet>
                <meta charSet="utf-8" />
                <title>pup.is - sækja eða heimsenda mat</title>
                <meta name="description" content="Sæktu mat eða fáðu heimsent - finndu eitthvað í gogginn á pup.is" />
                <meta name="keywords" content={'Veitingastaðir,takeaway,pickup,matur,heimsending,sækja,mat,borða,hádegismatur,hádegistilboð,restaurants'} />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={'pup.is - sækja eða heimsenda mat'} />
                <meta name="twitter:description" content={'Sæktu mat eða fáðu heimsent - finndu eitthvað í gogginn á pup.is'} />
                <meta name="twitter:image" content={MetaImage} />

                {/* Facebook / Pinterest */}
                <meta name="og:title" content={'pup.is - sækja eða heimsenda mat'} />
                <meta name="og:type" content="website" />
                <meta name="og:url" content={'https://pup.is/'} />
                <meta name="og:image" content={MetaImage} />
                <meta name="og:description" content={'Sæktu mat eða fáðu heimsent - finndu eitthvað í gogginn á pup.is'} />

                {/* <link rel="canonical" href={`${url}/p/${product.slug.current}`} /> */}

            </Helmet>
            <ApolloProvider>
                {routes.map((route, index) => {
                    return (
                        <route.routeType
                            key={index}
                            path={route.path}
                            exact={route.exact}
                        >
                            <route route={route}>
                                <>
                                    <Header />
                                    <route.component />
                                </>
                            </route>
                        </route.routeType>
                    )
                })}
                <ToastContainer />
            </ApolloProvider>
        </BrowserRouter>
    )
}

export default App
