import * as Yup from 'yup'
import _ from 'lodash'
import { PHONE_REGEX, URL_REGEX, HOUR_MINUTES_REGEX } from '../../../../../utils/regex'
import moment from 'moment'

function getInitialValues (restaurant) {
	if (restaurant) {
		return _.pick(restaurant, ['name', 'address', 'isVegan', 'phone', 'pickup', 'delivery', 'description', 'website', 'menuWebsite', 'imagePublicId', 'openingHours', 'specialOpeningHours'])
	} else {
		return INITIAL_VALUES
	}
}


const MAX_MINUTES_AFTER_MIDNIGHT = 270; //4:30
// Accepts a moment object, returns the minutes from midnight.
function minutesOfDay (m){
  return m.minutes() + m.hours() * 60;
}


const DEFAULT_OPENING_HOURS = {
  hours: [],
  isOpen: false
}

const INITIAL_VALUES = {
	name: '',
	address: {
		street: '',
		city: '',
		zipCode: ''
	},
	phone: '',
	pickup: false,
	delivery: false,
	isVegan: false,
	description: '',
	website: '',
	menuWebsite: '',
	imagePublicId: '',
	openingHours: {
		mon: DEFAULT_OPENING_HOURS,
		tue: DEFAULT_OPENING_HOURS,
		wed: DEFAULT_OPENING_HOURS,
		thu: DEFAULT_OPENING_HOURS,
		fri: DEFAULT_OPENING_HOURS,
		sat: DEFAULT_OPENING_HOURS,
		sun: DEFAULT_OPENING_HOURS
	},
	specialOpeningHours: []
}

const HOURS_VALIDATION_SCHEMA = Yup.array().of(
	Yup.object().shape({
		from: Yup.string().required('Þú þarft að fylla út frá').matches(HOUR_MINUTES_REGEX, 'Tími er ekki á réttu formi'),
		to: Yup
					.string()
					.required('Þú þarft að fylla út til')
					.matches(HOUR_MINUTES_REGEX, 'Tími er ekki á réttu formi')
					.test('is-greater', 'Tímar eru ekki á réttu formi',  function (value) {
						const { from } = this.parent;
						const fromMinutes = minutesOfDay(moment(from, 'HH:mm'));
						const toMinutes = minutesOfDay(moment(value, 'HH:mm'));
						if (fromMinutes > toMinutes) {
							// Check if to is after midnight.
							if (toMinutes <= MAX_MINUTES_AFTER_MIDNIGHT) {
								return true;
							}
						}
						return moment(value, 'HH:mm').isAfter(moment(from, 'HH:mm'))
					})
	}).test('is-greater', 'Tímar eru ekki á réttu formi', function (value) {
		// Check if hours array is only 1 length, return true.
		if (this.parent.length === 1) { return true }
		// Get hours we need to validate. - Validate only from2 against to.
		let { to: to1 } = this.parent[0];
		let { from: from2, to: to2 } = this.parent[1];
		const { from, to } = value;
		if (from !== from2 && to !== to2) {
			return true;
		}
		const to1Minutes = minutesOfDay(moment(to1, 'HH:mm'));
		if (to1Minutes <= MAX_MINUTES_AFTER_MIDNIGHT) {
			return false;
		}
		let isFromSameOrAfterTo1 = moment(from, 'HH:mm').isAfter(moment(to1, 'HH:mm'))
		return isFromSameOrAfterTo1
	}))
	.nullable()
	.max(2)

const DAYS_OPENING_HOURS_VALIDATION_SCHEMA = Yup.object().shape({
  isOpen: Yup.bool(),
	hours: HOURS_VALIDATION_SCHEMA
})

const SPECIAL_HOURS_VALIDATION_SCHEMA = Yup.object().shape({
  date: Yup.string().required('Þú þarft að velja dagsetningu'),
  isOpen: Yup.bool(),
	hours: HOURS_VALIDATION_SCHEMA
})

const BASE_RESTAURANT_VALIDATION_SCHEMA = Yup.object().shape({
	name: Yup.string().required('Þú þarft að fylla út nafn'),
	address: Yup.object().shape({
		street: Yup.string().required('Þú þarft að fylla út heimilisfang'),
		city: Yup.string().required('Þú þarft að fylla út borg'),
		zipCode: Yup.string().required('Þú þarft að fylla út póstnúmer')
	}),
	phone: Yup.string().nullable().matches(PHONE_REGEX, 'Símanúmer er ekki rétt'),
	pickup: Yup.boolean(),
	delivery: Yup.boolean(),
	isVegan: Yup.boolean().nullable(),
})

const ADDITIONAL_RESTAURANT_VALIDATION_SCHEMA = Yup.object().shape({
	description: Yup.string().max(200, 'Lýsing getur að hámarki verið 200 stafir'),
	imagePublicId: Yup.string().nullable(),
	website: Yup.string().nullable().matches(URL_REGEX, 'Vefsíða er ekki á réttu formi'),
	menuWebsite: Yup.string().nullable().matches(URL_REGEX, 'Vefsíða er ekki á réttu formi'),
	openingHours: Yup.object().shape({
		mon: DAYS_OPENING_HOURS_VALIDATION_SCHEMA,
		tue: DAYS_OPENING_HOURS_VALIDATION_SCHEMA,
		wed: DAYS_OPENING_HOURS_VALIDATION_SCHEMA,
		thu: DAYS_OPENING_HOURS_VALIDATION_SCHEMA,
		fri: DAYS_OPENING_HOURS_VALIDATION_SCHEMA,
		sat: DAYS_OPENING_HOURS_VALIDATION_SCHEMA,
		sun: DAYS_OPENING_HOURS_VALIDATION_SCHEMA
	}),
	specialOpeningHours: Yup.array().of(SPECIAL_HOURS_VALIDATION_SCHEMA)
});


export {
	getInitialValues,
	BASE_RESTAURANT_VALIDATION_SCHEMA,
	ADDITIONAL_RESTAURANT_VALIDATION_SCHEMA
}