import React from 'react'

// Forms
import { useFormikContext } from 'formik'

// UI
import { Input, Select, CheckBoxInput } from '../../../../../components/ui'

// Utils
import zipCodes from '../../../../../data/zip_codes'
import _ from 'lodash'

export default function Base () {
    const { values, setFieldValue } = useFormikContext()

    function getZipcodeValue () {
        const value = _.findIndex(zipCodes, { zip: values.address.zipCode });
        return value
    }
    
    return (
        <>
            <Input label='Nafn veitingastaðar' required name='name' placeholder='Nafn veitingastaðar' />
            <Input label='Heimilisfang' required name='address.street' placeholder='Gata' />
            <Select label='Póstnúmer' required name='address.zipCode' labelKey='label' options={zipCodes}
                value={getZipcodeValue()}
                defaultLabel={'Veldu póstnúmer'}
                defaultValue={-1}
                onChange={(e) => {
                    const value = zipCodes[e.target.value]
                    setFieldValue('address.zipCode', value.zip)
                    setFieldValue('address.city', value.city)
                }}
            />
            <Input
                label='Símanúmer fyrir pantanir'
                name='phone'
                onChange={(e) => {
                    let newVal = e.target.value;
                    var re = /^[0-9]*$/
                    if ((!newVal.length || re.test(newVal)) && !(newVal.length > 7)) {
                        setFieldValue('phone', newVal)
                    }
                }}
            />
            <p>Bjóðið þið upp á heimsendingu eða að sækja?*</p>
            <br />
            <CheckBoxInput label='Heimsending' name='delivery' />
            <CheckBoxInput label='Sækja' name='pickup' />
        </>
    )
}
