import { useQuery } from '@apollo/react-hooks'
import { RESTAURANT_INDEX } from '../graphql/queries'

const PAGE_SIZE = 10;

function useRestaurants() {
    const { error, data, loading, fetchMore, refetch } = useQuery(RESTAURANT_INDEX, {
        variables: { first: PAGE_SIZE, after: 0 },
        notifyOnNetworkStatusChange: true,
    });

    if (loading && !data ?.allRestaurants) return { loading, restaurants: [] };
    if (error) return { loading, restaurants: [] }

    const search = (query) => {
        return fetchMore({
            query: RESTAURANT_INDEX,
            notifyOnNetworkStatusChange: true,
            variables: {
                first: PAGE_SIZE,
                after: 0,
                query
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult.allRestaurants.edges;
                const pageInfo = fetchMoreResult.allRestaurants.pageInfo;
                return {
                    allRestaurants: {
                        edges: newEdges,
                        pageInfo
                    }
                }
            },
        });
    }

    const loadMore = (query) => {
        return fetchMore({
            query: RESTAURANT_INDEX,
            notifyOnNetworkStatusChange: true,
            variables: {
                first: PAGE_SIZE,
                after: data.allRestaurants.edges.length,
                query
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult.allRestaurants.edges;
                const pageInfo = fetchMoreResult.allRestaurants.pageInfo;
                return newEdges.length
                    ? {
                        allRestaurants: {
                            edges: [...previousResult.allRestaurants.edges, ...newEdges],
                            pageInfo
                        },
                    }
                    : previousResult;
            },
        });
    };

    return {
        restaurants: data.allRestaurants.edges.map(({ node }) => node),
        hasNextPage: data.allRestaurants.pageInfo.hasNextPage,
        loading,
        loadMore,
        refetch,
        search
    };
}

export default useRestaurants;