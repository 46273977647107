import { useState, useEffect } from 'react'
const LIKED_RESTAURANTS = 'LIKED_RESTAURANTS';

function useLikedRestaurants () {
    const [likedRestaurants, setLikedRestaurants] = useState([]);

    // Toggles a liked restaurant
    const toggleLikedRestaurant = (slug) => {
        try {
            const isLiked = isLikedRestaurant(slug);
            if (isLiked) {
                removeLikedRestaurant(slug)
            } else {
                addLikedRestaurant(slug)
            }
        } catch (err) {
            // ignore
        }
    }
        
    // Removes restaurant from liked array
    const removeLikedRestaurant = (slug) => {
        try {
            setLikedRestaurants(likedRestaurants.filter((r)=>(r !== slug)))
        } catch (err) {}
    };

    // Adds restaurant to liked array
    const addLikedRestaurant = (slug) => {
        try {
            setLikedRestaurants([...likedRestaurants, slug]);
        } catch (err) {}
    };

    // Returns if restaurant is liked
    const isLikedRestaurant = (slug) => {
        try {
            return likedRestaurants.indexOf(slug) > -1;
        } catch (err) {}
    }
 
    // Use effect hook that runs once, gets liked restaurants from localstorage and initializes the array.
    useEffect(() => {
        // Get current favorites and save to state.
        const currLikedRestaurants = localStorage.getItem(LIKED_RESTAURANTS);
        if (currLikedRestaurants) {
            setLikedRestaurants(JSON.parse(currLikedRestaurants));
        }
    }, []);

    // Use effect hook that runs when likedRestaurants changes.
    useEffect(() => {
        try {
            localStorage.setItem(LIKED_RESTAURANTS, JSON.stringify(likedRestaurants))
        } catch (err) {

        }
    }, [likedRestaurants])
    
    return {
        isLikedRestaurant,
        toggleLikedRestaurant,
    };
}

export default useLikedRestaurants;
