import gql from 'graphql-tag'

export const GET_ME = gql`
  query me {
    me {
      name
      email
    }
  }
`

export const MY_RESTAURANTS = gql`
  query myRestaurants ($first: Int!, $after: Int!){
    myRestaurants (first: $first, after: $after) {
      edges{
        node {
          id
          name
          description
          isVerified
          slug
          delivery
          pickup
          isVegan
          phone
          address {
            street
            city
            zipCode
          }
          website
          menuWebsite
          imagePublicId
          specialOpeningHours {
            date
            isOpen
            hours {
              from
              to
            }
          }
          openingHours {
            mon {
              hours {
                from
                to
              }
              isOpen
            }
            tue {
              hours {
                from
                to
              }
              isOpen
            }
            wed {
              hours {
                from
                to
              }
              isOpen
            }
            thu {
              hours {
                from
                to
              }
              isOpen
            }
            fri {
              hours {
                from
                to
              }
              isOpen
            }
            sat {
              hours {
                from
                to
              }
              isOpen
            }
            sun {
              hours {
                from
                to
              }
              isOpen
            }
          }
        }
      }
    }
  }
`

// Returns all restaurants, paginated
export const RESTAURANT_INDEX = gql`
  query restaurantIndex($first: Int!, $after: Int!, $query: String) {
    allRestaurants(first: $first, after: $after, query: $query) {
      pageInfo {
        hasNextPage
      }
      edges{
        node {
          name
          slug
          delivery
          pickup
          isVegan
          deliversTo
          description
          phone
          address {
            street
            city
            zipCode
          }
          website
          menuWebsite
          imagePublicId
          openingHours {
            hours {
              from
              to
            }
            isOpen
          }
        }
      }
    }
  }
`
