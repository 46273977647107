import { toast } from 'react-toastify';

export const mapGraphqlErrorsToArray = (error) => {
  return error.graphQLErrors.map(({ message }, i) => {
    return message
  })
}

export const displayGraphqlErrors = (error) => {
  if (error && error.graphQLErrors) {
    error.graphQLErrors.map(({ message }, i) => {
      return toast.error(message);
    })
  }
}