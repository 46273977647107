import React from 'react';
import { Link } from 'react-router-dom'
import './Header.scss';
// Assets
import Logo from '../../../assets/images/pup_logo.svg';
import UserIcon from '../../../assets/icons/user.svg';

import { Button } from '../';
import { useAuth } from '../../../hooks';

const Header = () => {
    const { user, signout } = useAuth();
    return (
        <div className="header">
            <Link to="/">
                <img src={Logo} alt='pup' />
            </Link>
            <div className="header-panel">
                <Link to={user ? '/' : '/login'} onClick={user ? signout : null}>
                    <Button>
                        <img src={UserIcon} alt='extraIcon' />
                        <p className="mutedText">{user ? 'Skrá út' : 'Innskráning'}</p>
                    </Button>
                </Link>
                <Link to={user ? '/home' : '/register'}>
                    <Button main>
                        {user ? 'Mitt svæði' : 'Skrá veitingahús'}
                    </Button>
                </Link>
            </div>
        </div>
    )
}

Header.defaultProps = {
    extraClick: () => { }
}

export default Header;