import React from 'react';
import {
  Route,
  Redirect
} from "react-router-dom";
import { isAuthenticated, isEmailVerified } from '../../services/token';
import PropTypes from 'prop-types';

export default function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthenticated()) {
          if (isEmailVerified()) {
            return children
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/verify-account",
                  state: { from: location }
                }}
              />
            )
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      }}
    />
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.node
}