import React, { useState, useEffect } from 'react'
import { useRestaurants, useLikedRestaurants } from '../../../hooks'

// Graphql
import InfinteScroll from 'react-infinite-scroller'

// UI
import { Hero, Expandable, Card, Container, Search } from '../../../components/ui';

// Assets
import SearchIcon from '../../../assets/icons/search.svg';
// import FilterIcon from '../../../assets/icons/filter.svg';
import { useMutation } from 'react-apollo';
import { CLICK_MUTATION } from '../../../graphql/mutations';

// Util
import { ServiceImage } from '../../../utils/Cloudinary';


const Home = () => {
    const [query, setQuery] = useState(null)
    const [isLoadMoreFinished, setIsLoadMoreFinished] = useState(true);
    const { restaurants, search, loadMore, hasNextPage, refetch } = useRestaurants();
    const [click] = useMutation(CLICK_MUTATION);

    function handleSearch(searchString) {
        setQuery(searchString);
        search(searchString)
    }

    // Run loadMore once on component mount
    useEffect(() => {
        if (refetch) {
            refetch();
        }
    }, [refetch])

    async function handleLoadMore() {
        if (isLoadMoreFinished) {
            setIsLoadMoreFinished(false)
            await loadMore(query)
            setIsLoadMoreFinished(true)
        }
    }

    function clickRestaurant(slug) {
        try {
            if (!!slug) {
                click({ variables: { slug } })
            }
        } catch (err) {
            // ignore
        }
    }

    return (
        <div>
            <Hero title={'Við hjálpum þér að finna eitthvað í gogginn'} subtitle={'Sæktu mat eða fáðu sent heim að dyrum frá hinum ýmsu veitingastöðum'}>
                <Expandable icon={SearchIcon} toolTip={'Leita að veitingahúsi'}>
                    <Search onChange={(e) => handleSearch(e.target.value)} />
                </Expandable>
                {/* <Expandable icon={FilterIcon} toolTip={'Sía veitingahús'}>
                    <p>Smegma</p>
                </Expandable> */}
            </Hero>
            <InfinteScroll
                threshold={1500}
                loadMore={handleLoadMore}
                hasMore={hasNextPage}
                loader={<p>Loading...</p>}
            >
                <Container>
                    <RestaurantList
                        restaurants={restaurants}
                        onClick={clickRestaurant}
                    />
                </Container>
            </InfinteScroll>
        </div>
    )
}

function RestaurantList({ restaurants, onClick }) {
    const {
        toggleLikedRestaurant,
        isLikedRestaurant
    } = useLikedRestaurants();
    return restaurants.map((restaurant, index) => (
        <Card
            onClick={() => onClick(restaurant.slug)}
            liked={isLikedRestaurant(restaurant.slug)}
            toggleLiked={() => toggleLikedRestaurant(restaurant.slug)}
            key={index}
            index={index}
            image={!!restaurant.imagePublicId && ServiceImage(restaurant.imagePublicId)}
            name={restaurant.name}
            address={restaurant.address.street}
            pickup={restaurant.pickup}
            isVegan={restaurant.isVegan}
            delivery={restaurant.delivery}
            description={restaurant.description}
            restaurant={restaurant}
            open={restaurant.openingHours.isOpen}
            open_from={restaurant.openingHours.hours?.from}
            open_to={restaurant.openingHours.hours?.to}
            phone={restaurant?.phone}
            website={restaurant.website}
            menuWebsite={restaurant.menuWebsite}
        />
    ))
}

export default Home;