import React from 'react';
import './DropArea.scss';

import Dropzone from 'react-dropzone'
import Loader from 'react-loader-spinner'
// Util
import { ServiceImage } from '../../../utils/Cloudinary';

// https://github.com/react-dropzone/react-dropzone/issues/252#issuecomment-253434028
// Dropzone will bubble the event up the parent, set it to null on label, and it wont fire twice.
const DropArea = ({ handleDrop, values, name, label, loading }) => (
    <label onClick={null}>
        {label}
        <Dropzone
            onDrop={(files) => handleDrop(files)}
            multiple={false}
            accept='image/*'
        >
            {({ getRootProps, getInputProps }) => (
                <section className="drop_area">
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div>
                            {
                                loading ?
                                    <Loader
                                        type="MutatingDots"
                                        color="#F26664"
                                        height={100}
                                        width={100}
                                    />
                                    :
                                    values[name] ?
                                        <img src={ServiceImage(values[name])} alt='Hlaða upp ljósmyndum'/>
                                        :
                                        <p>Ýttu hér eða dragðu myndina hingað til að upphlaða henni</p>
                            }
                        </div>
                    </div>
                </section>
            )}
        </Dropzone>
    </label>
)


export default DropArea;