import React, { useState } from 'react';
import './Card.scss';

// Assets
import HeartFilled from './icons/heart_filled.svg';
import HeartEmpty from './icons/heart_empty.svg';
import LeafFilled from './icons/leaf_filled.svg';
import PhoneWhite from './icons/phone_white.svg';

const Card = ({ index, image, name, address, onClick, liked, toggleLiked, pickup, isVegan, delivery, description, open, open_from, open_to, website, phone, menuWebsite }) => {
    const [clicked, setClicked] = useState(false);
    return (
        <div className="card" key={index}>
            {website ?
            <a href={website} target="_blank" rel="noopener noreferrer">
                <div className="image-wrapper" style={{ backgroundImage: `url(${image || `https://source.unsplash.com/600x${240 + index}/?restaurant`})` }} />
            </a>
            :
            <div className="image-wrapper" style={{ backgroundImage: `url(${image || `https://source.unsplash.com/600x${240 + index}/?restaurant`})` }} />
            }       
            <div className="body">
                <div className="top">
                    <h3>{name}</h3>
                    <p>{address}</p>
                    <div className="favorite" onClick={toggleLiked}>
                        <img className={liked ? 'active-img' : ''} src={liked ? HeartFilled : HeartEmpty} alt={'Favorite'} />
                    </div>
                </div>
                <div className="attributes">
                    {pickup && <div className="attribute" id="takeAway"><p>Sækja</p></div>}
                    {delivery && <div className="attribute" id="delivery"><p>Heimsending</p></div>}
                </div>
                <p className="content">
                    {description}
                </p>
                <div className="opening">
                    <div className={`circle ${open ? 'open' : 'closed'}`}></div>
                    {open && <p>Opið {open_from} - {open_to}</p>}
                    {!open && <p>Lokað</p>}
                </div>
            </div>
            <div className="cta">
                {
                    clicked ?
                        <div className="clicked-container">
                            {
                                phone &&
                                <a href={`tel: +354${phone}`}>
                                    <button className="phone">
                                        <img className="phoneImg" src={PhoneWhite} alt="Vegan" />
                                        {phone.substring(0,3)}-{phone.substring(3)}
                                    </button>
                                </a>
                            }
                            {
                                menuWebsite &&
                                <a href={menuWebsite} target="_blank" rel="noopener noreferrer">
                                    <button className="menu">
                                        Matseðill
                                    </button>
                                </a>
                            }
                        </div>
                        :
                        <div onClick={e => setClicked(true)}>
                            <button className="moreBtn" onClick={onClick}>
                                {!!isVegan && <img className="leaf" src={LeafFilled} alt="Vegan" />} 
                                <div className="more">Skoða nánar</div>
                        </button>
                        </div>
                }
            </div>
        </div>
    )
}

export default Card;