import React from "react";
import { Formik } from "formik";
import Form from "../Form/Form";

// Assets
import CutleryIcon from '../../../assets/icons/cutlery.svg';
import BriefcaseIcon from '../../../assets/icons/briefcase.svg';

export default class Wizard extends React.Component {
    static Page = ({ children }) => children;

    constructor(props) {
        super(props);
        this.state = {
            page: props.initialStep,
            values: props.initialValues
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.initialValues !== this.props.initialValues) {
            this.setState({ values: this.props.initialValues })
        }
        if (prevState.page !== this.props.initialStep) {
            this.setState({ page: this.props.initialStep })
        }
    }

    next = values =>
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values
        }));

    previous = () =>
        this.setState(state => ({
            page: Math.max(state.page - 1, 0)
        }));

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[
            this.state.page
        ];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };

    handleSubmit = (values, bag) => {
        const { children, onSubmit, create } = this.props;
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;
        if (isLastPage || !create) {
            // Last page or are we creating ?
            return onSubmit(values, bag);
        } else {
            bag.setTouched({});
            bag.setSubmitting(false);
            this.next(values);
        }
    };

    setBasic = () => {
        this.setState({ page: 0 });
    }

    setAdditional = () => {
        this.setState({ page: 1 });
    }

    render() {
        const { children, validationSchemas, labels, submitLabels } = this.props;
        const { page, values } = this.state;

        const activePage = React.Children.toArray(children)[page];
        // const isLastPage = page === React.Children.count(children) - 1;
        const { create } = this.props;

        return (
            <Formik
                initialValues={values}
                enableReinitialize={true}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchemas[page]}
                onSubmit={this.handleSubmit}
            >
                {props => {
                    const { handleSubmit } = props;
                    const tabConfigurationProps = create ?
                        {
                            onGoBack: page !== 0 ? this.previous : null
                        }
                        :
                        {
                            actions:
                                [
                                    {
                                        action: this.setBasic.bind(this),
                                        icon: CutleryIcon,
                                        label: 'Grunnupplýsingar',
                                    },
                                    {
                                        action: this.setAdditional.bind(this),
                                        icon: BriefcaseIcon,
                                        label: 'Valfrjálsar upplýsingar',
                                    }
                                ]

                        }


                    return (
                        <Form
                            label={labels[page]}
                            onSubmit={handleSubmit}
                            submitLabel={submitLabels[page]}
                            activeAction={page}
                            {...tabConfigurationProps}
                        >
                            {/* {React.cloneElement(activePage, { parentState: { ...props } })} */}
                            {activePage}
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}
