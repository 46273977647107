import React from 'react'
import { useHistory } from 'react-router-dom'

// Forms
import { Formik } from 'formik'
import * as Yup from 'yup'

// Graphql
import { useMutation } from '@apollo/react-hooks'
import { displayGraphqlErrors } from '../../../graphql/utils'
import { LOGIN_MUTATION } from '../../../graphql/mutations'

// UI
import { Input, Form } from '../../../components/ui'
import { useAuth } from '../../../hooks'


const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Netfang er ekki á réttu formi')
        .required('Þú þarft að fylla út netfang'),
    password: Yup.string()
        .required('Þú þarft að fylla út lykilorð')
})

const tabs = [
    {
        label: 'Innskráning',
        link: '/login',
        active: true,
    },
    {
        label: 'Nýskráning',
        link: '/register'
    }
]

export default function Login() {
    const { signin } = useAuth();
    const history = useHistory()
    const [login] = useMutation(LOGIN_MUTATION)

    return (
        <Formik
            initialValues={{
                email: '',
                password: ''
            }}
            validationSchema={LoginSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async values => {
                try {
                    const { data } = await login({ variables: { input: values } })
                    await signin(data.login.token);
                    history.push('/home')
                } catch (err) {
                    displayGraphqlErrors(err)
                }
            }}
        >
            {({ handleSubmit }) => {
                return (
                    <Form onSubmit={handleSubmit} submitLabel={'Innskrá'} tabs={tabs} centered>
                        <Input label='Netfang' name='email' required />
                        <Input label='Lykilorð' name='password' required type='password' />
                    </Form>
                )
            }}
        </Formik>
    )
}

