import React from 'react';
import './Hero.scss';

const Hero = ({ title, subtitle, children }) => (
    <div className="hero">
        <div className="titles">
            <h1>{title}</h1>
            <h4>{subtitle}</h4>
        </div>
        <div className="body">
            {children}
        </div>
    </div>
)

export default Hero;