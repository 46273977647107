import React from 'react';
import { Link } from 'react-router-dom';
import './RestaurantList.scss';

const RestaurantList = ({ selectRestaurant, restaurants, active, actions }) => (
    <div className="restaurants-wrapper">
        <div className="restaurant-list">
            {restaurants.map(({ node }, index) => (
                <button className='restaurant-item' onClick={() => selectRestaurant(node)} key={index}>
                    {(node ?.id === active ?.id) && <div className="active" />}
                    <h3>{node.name}</h3>
                    {!node.isVerified && <div className="restaurant-tag"><h3>í ferli</h3></div>}
                </button>
            ))}
        </div>
        {!!actions && <div className="restaurant-actions">
            {
                actions.map((action, i) =>
                    <Link to={action.path}>
                        <button className='action-item' key={i}>
                            <img src={action.icon} alt={action.label} />
                            {action.label}
                        </button>
                    </Link>
                )
            }
        </div>}
    </div>
)

export default RestaurantList;