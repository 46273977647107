import React from 'react';
import { Link } from 'react-router-dom'
import './Form.scss';

import BackArrow from './icons/back_arrow.svg';

const Form = ({ children, submitLabel, label, tabs, extra, centered, activeAction, onGoBack, handleSubmit, actions, ...rest }) => (
    <form className={`form ${centered ? 'centered' : ''}`} {...rest}>
        {tabs &&
            <div className="tabs">
                {tabs.map(tab =>
                    <Link to={tab.link} className={`tab ${tab.active ? 'active' : ''}`}>
                        <h2>{tab.label}</h2>
                    </Link>
                )}
            </div>
        }
        {label &&
            <div className="tabs">
                <h2 className="label">{label}</h2>
            </div>
        }
        {
            onGoBack &&
            <button type="button" className="back" onClick={e => onGoBack()}>
                <img src={BackArrow} alt="tilbaka" />
            </button>
        }
        {
            actions &&
            <div className="actions">
                {
                    actions.map((action, index) =>
                        <button type="button"  onClick={action.action}>
                            <img className={(activeAction === index) ? 'active-img' : 'unactive-img'} src={action.icon} alt={action.label} />
                        </button>
                    )
                }
            </div>
        }
        {children}
        <button type='submit' className="submit-button" onSubmit={handleSubmit}>
            {submitLabel}
        </button>
        <div className="extra">
            {extra}
        </div>
    </form>
)

export default Form;