import gql from 'graphql-tag'

export const REGISTER_MUTATION = gql`
	mutation register($input: CreateUserInputType!) {
		register(createUserInput: $input) {
			token
		}
	}
`

export const LOGIN_MUTATION = gql`
	mutation login($input: LoginUserInputType!) {
		login(loginUserInput: $input) {
			token
		}
	}
`

export const CLICK_MUTATION = gql`
	mutation clickRestaurant ($slug: String!) {
		clickRestaurant(slug: $slug) {
			message
		}
	}
`

export const CREATE_RESTAURANT_MUTATION = gql`
	mutation createRestaurant($input: CreateRestaurantInputType!) {
		createRestaurant(createRestaurantInput: $input) {
			id
		}
	}
`

export const UPDATE_RESTAURANT_MUTATION = gql`
	mutation updateRestaurant($id: ID!, $input: EditRestaurantInputType!) {
		updateRestaurant(id: $id, editRestaurantInput: $input) {
			id
		}
	}
`

export const GENERATE_SIGNATURE = gql`
	mutation generateSignature {
		generateSignature {
			signature
			timestamp
			api_key
			public_id
		}
	}
`
