import React from 'react'
import { useHistory, Link } from 'react-router-dom'

// Forms
import { Formik } from 'formik'
import * as Yup from 'yup'

// Graphql
import { REGISTER_MUTATION } from '../../../graphql/mutations'
import { useMutation } from '@apollo/react-hooks'
import { setTokenAsync } from '../../../services/token'
import { displayGraphqlErrors } from '../../../graphql/utils'

// UI
import { Input, Form, CheckBoxInput } from '../../../components/ui'



const RegisterSchema = Yup.object().shape({
    name: Yup.string()
        .required('Þú þarft að fylla út nafn'),
    email: Yup.string()
        .email('Netfang er ekki á réttu formi')
        .required('Þú þarft að fylla út netfang'),
    password: Yup.string()
        .required('Þú þarft að fylla út lykilorð'),
    terms: Yup.bool().oneOf([true], 'Þú þarft að samþykkja skilmála')
})

const tabs = [
    {
        label: 'Innskráning',
        link: '/login',
    },
    {
        label: 'Nýskráning',
        link: '/register',
        active: true,
    }
]

export default function Register() {
    const history = useHistory()
    const [register] = useMutation(REGISTER_MUTATION)

    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                password: '',
                terms: false
            }}
            validationSchema={RegisterSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async values => {
                try {
                    const { data } = await register({ variables: { input: values } })
                    await setTokenAsync(data.register.token)
                    history.push('/restaurant')
                } catch (err) {
                    displayGraphqlErrors(err)
                }
            }}
        >
            {({ handleSubmit }) => {
                return (
                    <Form onSubmit={handleSubmit} submitLabel={'Nýskrá'} tabs={tabs} centered>
                        <Input label='Nafn notanda' name='name' required />
                        <Input label='Netfang' name='email' required />
                        <Input label='Lykilorð' name='password' required type='password' />
                        <br/>
                        <CheckBoxInput
                            child={
                                <p>Ég samþykki <Link to='/terms'>skilmála</Link> pup.is*</p>
                            }
                            name='terms'
                        />
                    </Form>
                )
            }}
        </Formik>
    )
}
