import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'formik'

import { OpeningHours } from '../../../../../components/ui';

export default function HoursInput({
    name,
    values,
    setFieldValue,
    objectKey,
    label
}) {
    const fieldArrayName = `${name}.${objectKey}.hours`
    const checkBoxName = `${name}.${objectKey}.isOpen`
    const currDateField = values[name][objectKey]
    const currOpeningHours = currDateField.hours
    const isCheckedOpen = currDateField.isOpen

    return (
        <FieldArray name={fieldArrayName}>
            {({ remove, push }) => {
                const canAdd = isCheckedOpen && currOpeningHours.length < 2
                const canEditHours = isCheckedOpen && currOpeningHours.length > 0
                return (
                    <OpeningHours
                        label={label}
                        checkBoxName={checkBoxName}
                        isCheckedOpen={isCheckedOpen}
                        canEditHours={canEditHours}
                        currOpeningHours={currOpeningHours}
                        objectKey={objectKey}
                        remove={remove}
                        push={push}
                        setFieldValue={setFieldValue}
                        fieldArrayName={fieldArrayName}
                        canAdd={canAdd}
                    />
                )
            }}
        </FieldArray>
    )
}

HoursInput.propTypes = {
    name: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    objectKey: PropTypes.any.isRequired,
    label: PropTypes.string
}

HoursInput.defaultProps = {
    label: null
}
