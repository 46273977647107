import React from 'react';
import './CheckBoxInput.scss';

import { Field, ErrorMessage } from 'formik'

const CheckBoxInput = ({ label, child, name, required, ...rest }) => (
    <label className="checkbox-container">
        <p className="label">{label}</p>
        {child && child}
        <ErrorMessage name={name} component='div' className='error' />
        <Field type="checkbox" name={name} required={required} {...rest} />
        <span className="checkmark"></span>
    </label>
)

export default CheckBoxInput;