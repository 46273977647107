// Router Types
import PrivateRoute from '../components/routes/privateRoute'
import PublicRoute from '../components/routes/publicRoute'

// Not authenticated routes
import Home from './not-authenticated/home'
import Login from './not-authenticated/login'
import Register from './not-authenticated/register'
import Terms from './not-authenticated/terms'
// import ForgotPassword from './not-authenticated/forgot-password'
// import Restaurant from './not-authenticated/restaurant'
// import VerifyAccount from './not-authenticated/verify-account'

// Authenticated routes
import AuthHome from './authenticated/home'
import AuthRestaurant from './authenticated/restaurant/create'

export default [
  {
    path: '/',
    exact: true,
    component: Home,
    routeType: PublicRoute
  },
  // {
  //   path: '/r/:slug',
  //   exact: true,
  //   component: Restaurant,
  //   routeType: PublicRoute
  // },
  {
    path: '/login',
    exact: true,
    component: Login,
    routeType: PublicRoute
  },
  {
    path: '/register',
    exact: true,
    component: Register,
    routeType: PublicRoute
  },
  {
    path: '/terms',
    exact: true,
    component: Terms,
    routeType: PublicRoute
  },
  // {
  //   path: '/forgot-password',
  //   exact: true,
  //   component: ForgotPassword,
  //   routeType: PublicRoute
  // },
  // {
  //   path: '/verify-account',
  //   exact: true,
  //   component: VerifyAccount,
  //   routeType: PublicRoute
  // },
  {
    path: '/home',
    exact: true,
    component: AuthHome,
    routeType: PrivateRoute
  },
  {
    path: '/restaurant',
    exact: true,
    component: AuthRestaurant,
    routeType: PrivateRoute
  }
]
