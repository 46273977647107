import React from 'react';
import './Textarea.scss';

// Formik
import { Field, ErrorMessage } from 'formik'

const Textarea = ({ label, name, required, maxLength, placeholder, ...rest }) => (
    <label className="textarea_item">
        {label} {required ? '*' : ''}
        <ErrorMessage name={name} component='div' className='error' />
        <Field name={name}>
            {({ field }) => {
                return (
                    <div>
                        <textarea placeholder={placeholder} type="textarea" {...field} {...rest} />
                        {maxLength && <p className='textarea-detail'>{field.value.length} / {maxLength}</p>}
                    </div>
                )
            }}
        </Field>
    </label>
)

export default Textarea;