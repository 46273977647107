import React from 'react';
import './Container.scss';

const Container = ({ children }) => (
    <div className="item-container">
        {children}
        <div className="card empty"></div>
        <div className="card empty"></div>
        <div className="card empty"></div>
        <div className="card empty"></div>
    </div>
)

export default Container;