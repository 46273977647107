import React, { useState } from 'react';
import './Expandable.scss';

// import { Tooltip } from 'react-tippy';

const Expandable = ({ icon, toolTip, children }) => {
    const [active, setActive] = useState(false);

    return (
        <div className="expandable-container">
            {/* <Tooltip
                // options
                title={toolTip}
                position="left"
                trigger="mouseenter"
            > */}
            <button onClick={e => setActive(!active)} className="expandable">
                {icon && <img src={icon} alt="icon"/>}
            </button>
            {/* </Tooltip> */}
            {
                active &&
                <div className="expanded">
                    {children}
                </div>
            }
        </div>
    )
}

export default Expandable;