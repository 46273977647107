import React, { useState, useEffect } from 'react'

// Graphql
import { useQuery } from '@apollo/react-hooks'
import { MY_RESTAURANTS } from '../../../graphql/queries'

// UI
import { AuthContainer, RestaurantList } from '../../../components/ui';
import RestaurantForm from './components/form/RestaurantForm';

// Routing
import { useHistory } from 'react-router-dom'

// Assets
import PlusIcon from '../../../assets/icons/plus.svg';

// Forms
const PAGE_SIZE = 150

const Home = () => {
  const [after] = useState(0)
  const history = useHistory()
  const [selectedRestaurant, setSelectedRestaurant] = useState(null)
  const { data, loading, error, refetch } = useQuery(MY_RESTAURANTS, { fetchPolicy: 'no-cache', variables: { first: PAGE_SIZE, after } })

  function onFinish() {
    // setSelectedRestaurant(null)
    refetch()
  }

  function handleSelectRestaurant (restaurant) {
    if (restaurant?.id === selectedRestaurant?.id) {
      setSelectedRestaurant(null)
    } else {
      setSelectedRestaurant(restaurant)
    }
  }

  useEffect(() => {
    if (!loading && !error) {
      if (data?.myRestaurants?.edges.length) {
        setSelectedRestaurant(data.myRestaurants.edges[0].node)
      } else {
        // No restaurants 
        history.push('/restaurant')
      }
    }
  }, [data, loading, error, history])

  if (error) {
    return null;
  }

  if (loading) {
    return null;
  }

  return (
    <AuthContainer>
      <div className="tempWrapper">
        {
          <RestaurantList
            restaurants={data.myRestaurants.edges}
            selectRestaurant={(restaurant) => handleSelectRestaurant(restaurant)}
            active={selectedRestaurant}
            createPath={'/create'}
            actions={[
              {
                path: '/restaurant',
                icon: PlusIcon,
                label: 'Nýtt veitingahús',
              }
            ]}
          />
        }
        {!!selectedRestaurant &&
            <RestaurantForm
              onFinish={onFinish}
              restaurant={selectedRestaurant}
            />
        }
      </div>
    </AuthContainer>
  )
}

export default Home;