import React from 'react';
import './TimeInput.scss';

import RemoveIcon from '../../../assets/icons/remove.svg';

// Formik
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';

const TimeInput = ({ label, onValueChange, error, value, required, removeButton }) => {
	return (
		<label className={`input_item ${removeButton ? 'short' : ''}`}>
				{label} {required ? '*' : ''}
				<TimePicker
						defaultValue={value ? moment(value, 'HH:mm') : null}
						onChange={(val) => { onValueChange(val.format('HH:mm')) }}
						allowEmpty={false}
						showSecond={false}
						minuteStep={15}
						className={error ? 'time-error' : ''}
				/>
				{
						removeButton &&
						<button type="button" onClick={removeButton} className='remove-button'>
								<img src={RemoveIcon} alt="fjarlægja" />
						</button>
				}
		</label>
	)
}

export default TimeInput;