import React from 'react'
import RestaurantForm from '../../home/components/form/RestaurantForm'
import { useHistory } from 'react-router-dom'
import { AuthContainer } from '../../../../components/ui';
export default function CreateRestaurant() {

	const history = useHistory()

	return (
		<AuthContainer>
			<RestaurantForm
				restaurant={null}
				onFinish={() => history.push('/home')}
				create={true}
			/>
		</AuthContainer>
	)
}