import React from 'react'
import { Container } from '../../../components/ui';
import Block from './components/Block/Block';

export default function Terms () {
  return (
    <Container>
      <Block>
        <h2>Skilmálar PUP</h2>
        <p>Síðast uppfært 15. Apríl 2020</p>
      </Block>
      <Block>
        <p>
          PUP ehf., kt. xxxxxx-xxxx, og ofangreindur viðskiptavinur, (hér eftir „viðskiptavinurinn“), hér eftir sameiginlega nefndir „aðilar“, gera með sér eftirfarandi samning um þjónustu.
        </p>
      </Block>
      <Block>
        <h3>1. Þjónusta PUP</h3>
        <p>
          1. Þjónusta PUP felst í því að gera veitingahúsum kleift að birta upplýsingar og selja mat á markaðstorgi PUP, sem og taka við greiðslum frá notendum PUP. Veitingahús geta einnig séð gögn um kauphegðun notenda PUP og notað þau gögn í markaðslegum tilgangi. PUP uppfyllir öll skilyrði GDPR. PUP veitir viðskiptavini jafnframt ráðgjöf á sölu og framsetningu á PUP.is.
        </p>
        <p>
          Gjald fyrir framangreinda þjónustu er samkvæmt verðskrá. Feli PUP þriðja aðila að gefa út og/eða innheimta reikninga vegna greiðslu þóknana samkvæmt samningi þessum, að hluta eða heild, mun PUP upplýsa viðskiptavin um það
        </p>
      </Block>
      <Block>
        <h3>2. Greiðsluþjónusta</h3>
        <p>
          1. Sala og uppgjör á PUP er í Íslenskum Krónum (ISK)
        </p>
        <p>
          2. Viðskiptavinur skal skrifa undir samstarfssamning við KORTA er <span>varðar uppgjör vegna sölu sem á sér stað</span> inn á PUP. <span>Greiðsluþóknun KORTA er innifalin í söluþóknun PUP.</span>
        </p>
      </Block>

      <Block>
        <h3>3. Efni á PUP</h3>
        <p>
          1. Viðskiptavinurinn og PUP samþykkja að allt efni (textar, myndir, verð og fleira) tengt vörum og/eða þjónustu viðskiptavinar á PUP verði handvirkt sett inn af viðskiptavini í gegnum PUP-bakenda viðskiptavinar
        </p>
        <p>
          2. Viðskiptavinurinn er ábyrgur fyrir notkun á öllu sínu efni og innihaldi þess á PUP. Viðskiptavinurinn ábyrgist einnig að hann eigi og/eða hafi nauðsynlegar heimildir (höfundarrétt og önnur eignarréttindi) til að nota og heimila notkun á fyrrnefndu efni á PUP.
        </p>
        <p>
          3. Viðskiptavinurinn er ávallt ábyrgur fyrir réttum og uppfærðum upplýsingum um vörur og/ eða þjónustu sína á PUP og ábyrgist að þær upplýsingar séu alltaf sannar, réttar og ekki villandi. Viðskiptavinurinn tekur á sig alla áhættu sem tengist nákvæmni, heilleika eða notagildi þeirra upplýsinga sem viðskiptavinurinn gefur frá sér.
        </p>
        <p>
          4. Viðskiptavinurinn er ábyrgur fyrir því að uppfæra og breyta eigin efni og upplýsingum á PUP.<br />
          Villur eða vandamál sem verða til vegna úrelta upplýsinga eru á ábyrgð viðskiptavinarins. PUP áskilur sér rétt til að fjarlægja upplýsingar viðskiptavinar án fyrirvara.
        </p>
        <p>
          5. Viðskiptavinurinn getur skapað sér skaðabótaskyldu (gagnvart PUP og/eða notendum þess) ef til dæmis efni brýtur í bága við réttindi þriðja aðila, þar með talið höfundarrétt, vörumerki, einkaleyfi, viðskiptaleynd, siðferðisrétt, einkalíf, rétt til kynningar eða annan hugverkarétt eða eignarrétt; inniheldur efni sem er ósatt, villandi eða ærumeiðandi;
          inniheldur efni sem er ólögmætt, þar með talið ólögleg hatursorð eða klám; nýtir eða skaðar börn á annan hátt; eða brýtur gegn lögum eða reglugerðum. 
        </p>
        <p>
          6. PUP er heimilt að birta auglýsingar og aðrar upplýsingar samhliða efni viðskiptavinar á PUP eða öðrum miðlum. PUP er einnig heimilt að nota efni viðskiptavinar á PUP til auglýsinga. -Viðskiptavinurinn á ekki rétt á neinum bótum eða greiðslum vegna slíkra auglýsinga eða annarar notkunar á efninu.
        </p>
      </Block>

      <Block>
        <h3>1. Skyldur viðskiptavinar</h3>
        <p>
          1. Viðskiptavinurinn greiðir PUP þóknun af seldum vörum (mat eða vörum?) í gegnum PUP vefinn í samræmi við verðskrá. Veltutengd þóknun er dregin af söluþjónustuveltu fyrir skil hennar til viðskiptavinar.
        </p>
        <p>
          2. Viðskiptavinurinn skuldbindur sig til að bjóða notendum PUP sama verð fyrir vörur sínar og þjónustu og almennt gildir.
        </p>
        <p>
          3. Viðskiptavinurinn ber ábyrgð á því að koma seldum vörum til kaupenda (notenda PUP). Viðskiptavinurinn skuldbindur sig einnig til að koma seldum vörum eins fljótt og örugglega til kaupenda eins og aðstæður leyfa.
        </p>
        <p>
          4. Viðskiptavinurinn ábyrgist að lagerhald á PUP sé satt og rétt - (availability)
        </p>
      </Block>

      <Block>
        <h3>2. Skyldur PUP</h3>
        <p>
          1. PUP tryggir fyllsta trúnaði á öllum þeim gögnum sem koma vegna notkun PUP. <span>Öll gögn sem PUP safnar um veitingahús eru einungis aðgengileg PUP og því veitingahúsi sem átt er við. PUP deilir hvorki né selur gögn um veitingahús til þriðja aðila.</span>
        </p>
        <p>
          2. Undir engum kringumstæðum er PUP ábyrgt fyrir neinu af eftirfarandi tjónum eða tjóni (hvort sem slíkt tap er fyrirséð, fyrirsjáanlegt, þekkt eða á annan hátt): (a) tap á gögnum; (b) tekjutap eða tap á áætluðum hagnaði; (c) tap á viðskiptum; (d) tap af tækifærum; (e) tap á velvild eða mannorðsskaði; (f) tjón af þriðja aðila; eða (g) hvers kyns óbeinna, afleiddra, sérstaks eða fyrirmyndar tjóna hlýst af notkun smáforritsins eða vefsíðunni.
        </p>
      </Block>

      <Block>
        <h3>1. Vanefndarákvæði</h3>
        <p>
          1. Verði vanskil af hálfu viðskiptavinar á greiðslu fyrir þjónustu, er PUP heimild að krefjast dráttarvaxta samkvæmt lögum nr. 38/2001, um vexti og verðtryggingu, á hverjum tíma af þeirri fjárhæð sem er í vanskilum.
        </p>
        <p>
          1. Hvorum aðila um sig er heimilt að rifta samningi þessum ef um verulegar vanefndir er að ræða. Riftun skal miðast við næstu mánaðarmót eftir að riftunaryfirlýsing hefur verið send. Riftunin skal vera skrifleg, rökstudd og send gagnaðila með sannanlegum hætti. Að öðru leyti gilda almennar reglur kröfuréttar. Verulegar vanefndir samkvæmt ákvæðinu er meðal annars:
        </p>
        <p>
          1. ef aðili innir ekki af hendi greiðslur samkvæmt samningi þessum að undangenginni skriflegri greiðsluáskorun með 15 daga fyrirvara; og
        </p>
        <p>
          2. ef bú aðila er tekið til gjaldþrotaskipta, gert er árangurslaust fjárnám hjá honum, hann leitar formlega nauðasamninga, eða önnur staðfesting á tilkominni eða fyrirsjáanlegri ógreiðslugærni hans liggur fyrir.
        </p>
      </Block>

      <Block>
        <h3>2. Gildistími og uppsögn</h3>
        <p>
          1. Samningur þessi tekur gildi við undirritun og heldur gildi sínu í X mánuði. Eftir það er hvorum aðila um sig heimilt að segja samningnum upp með X mánaða fyrirvara. Viðskiptavinurinn er þó ekki skuldbundinn að birta framboð sitt á PUP nema hann kjósi að gera svo.
        </p>
      </Block>

      <Block>
        <h3>1. Framsal, varnarþing, ofl.</h3>
        <p>
          1. Viðskiptavini er með öllu óheimilt að framselja samning þennan til 3ja aðila, nema með skýru og ótvíræðu samþykki PUP.
        </p>
        <p>
          2. Um samning þennan gilda íslensk lög. Ef upp koma ágreiningsmál um framkvæmd samnings þessa, sem ekki er hægt að leysa með samkomulagi aðila, skal reka mál fyrir Héraðsdómi Reykjavíkur.
        </p>
        <p>
          3. Samningur þessi er gerður í tveimur samhljóða frumritum, einu fyrir hvorn aðila 
        </p>
      </Block>
    </Container>
  )
}
