import React, { useState, useEffect } from 'react'
import Wizard from '../../../../../components/ui/Wizard/Wizard'
import { getInitialValues, BASE_RESTAURANT_VALIDATION_SCHEMA, ADDITIONAL_RESTAURANT_VALIDATION_SCHEMA } from './lib'
import Base from './Base'
import Additional from './Additional'

// GraphQL
import { CREATE_RESTAURANT_MUTATION, UPDATE_RESTAURANT_MUTATION } from '../../../../../graphql/mutations'
import { useMutation } from '@apollo/react-hooks'
import { displayGraphqlErrors } from '../../../../../graphql/utils'

export default function RestaurantForm({ restaurant, onFinish, initialStep = 0 }) {
	const [initialValues, setInitialValues] = useState(getInitialValues(null))
	const [step, setStep] = useState(initialStep)
	const [create] = useMutation(CREATE_RESTAURANT_MUTATION)
	const [edit] = useMutation(UPDATE_RESTAURANT_MUTATION)

	useEffect(() => {
		setInitialValues(getInitialValues(restaurant))
		setStep(0)
	}, [restaurant])

	async function handleSubmit(values) {
		try {
			if (restaurant?.id) {
				await edit({ variables: { id: restaurant.id, input: values } })
			} else {
				await create({ variables: { input: values } })
			}
		} catch (err) {
			displayGraphqlErrors(err)
		} finally {
			onFinish()
		}
	}

	return (
		<Wizard
			initialStep={step}
			initialValues={initialValues}
			validationSchemas={[BASE_RESTAURANT_VALIDATION_SCHEMA, ADDITIONAL_RESTAURANT_VALIDATION_SCHEMA]}
			onSubmit={handleSubmit}
			submitLabels={restaurant ? ['Vista', 'Vista'] : ['Halda áfram', 'Stofna']}
			labels={['Grunnupplýsingar', 'Valfrjálsar upplýsingar']}
			create={!restaurant}
		>
			<Wizard.Page>
				<Base />
			</Wizard.Page>
			<Wizard.Page>
				<Additional />
			</Wizard.Page>
		</Wizard>
	)
}
