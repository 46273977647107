import React, { useState } from 'react'
import { useFormikContext, FieldArray } from 'formik'
import { getDayForKey } from '../../../../../utils/lib'

// UI
import HoursInput from '../input/HoursInput'
import { DateInput, Input, Textarea, DropArea, CheckBoxInput } from '../../../../../components/ui';
import { toast } from 'react-toastify';

// Graphql
import { useMutation } from '@apollo/react-hooks'
import { GENERATE_SIGNATURE } from '../../../../../graphql/mutations'

// Util
import axios from 'axios'
import PropTypes from 'prop-types'
import moment from 'moment'

export default function Additional() {
    // State
    const [uploadSignature] = useMutation(GENERATE_SIGNATURE)
    const { values, setFieldValue } = useFormikContext()
    const [imageLoading, setImageLoading] = useState(false);

    // Image upload
    async function handleDrop(files) {
        try {
            setImageLoading(true);
            const { data } = await uploadSignature()
            const fd = new FormData()

            fd.append('file', files[0])
            fd.append('signature', data.generateSignature.signature)
            fd.append('timestamp', data.generateSignature.timestamp)
            fd.append('public_id', data.generateSignature.public_id)
            fd.append('api_key', data.generateSignature.api_key)

            const response = await axios.post('https://api.cloudinary.com/v1_1/dudujlegd/image/upload', fd, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
            const responseData = response.data

            setFieldValue('imagePublicId', responseData.public_id)
        } catch (err) {
            toast.error(err.response.data.error.message)
        } finally {
            setImageLoading(false);
        }
    }

    return (
        <>
            <Textarea
                label='Lýsing'
                name='description'
                placeholder='Lýsing'
                maxLength={150}
                onChange={(e) => {
                    let val = e.target.value;
                    console.log('onChane called', val)
                    if (val.length <= 150) {
                        setFieldValue('description', val)
                    }
                }}
            />

            <Input
                label='Vefsíða'
                name='website'
                placeholder='Vefsíða'
                onChange={e => {
                    let value = e.target.value || "";
                    value = value.toLowerCase().trim();
                    setFieldValue('website', value);
                }}
            />

            <Input
                label='Hlekkur á matseðil'
                name='menuWebsite'
                placeholder='Hlekkur á matseðil'
                onChange={e => {
                    let value = e.target.value || "";
                    value = value.toLowerCase().trim();
                    setFieldValue('menuWebsite', value);
                }}
            />
            
            <DropArea
                label='Ljósmynd'
                handleDrop={handleDrop}
                values={values}
                name='imagePublicId'
                loading={imageLoading}
            />

            <p>Bjóðið þið upp á vegan möguleika?</p>
            <br />
            <CheckBoxInput label='Já' name='isVegan' />
            <br />
            <DaysInput
                label="Opnunartímar"
                values={values}
                setFieldValue={setFieldValue}
            />

            <SpecialOpeningHoursInput
                label="Sérstakir opnunartímar"
                values={values}
                setFieldValue={setFieldValue}
            />
        </>
    )
}

function SpecialOpeningHoursInput({ values, setFieldValue, label }) {
    return (
        <div>
            <FieldArray name='specialOpeningHours'>
                {({ remove, push }) => (
                    <div style={{ marginBottom: '30px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', }}>
                            <label style={{ display: 'block' }}>{label}</label>
                            <label style={{ opacity: 0.5, cursor: 'pointer' }} type='button' onClick={() => push({ date: new Date(), hours: [], isOpen: false })} >+ Bæta við </label>
                        </div>

                        {!!values.specialOpeningHours.length && values.specialOpeningHours.map((item, index) => {
                            let value = moment.unix(item.date / 1000).format('MM/DD/YYYY')
                            return (
                                <div key={`special-${index}`}>
                                    <DateInput
                                        name={`specialOpeningHours.${index}.date`}
                                        value={Date.parse(value)}
                                        onChange={(date) => setFieldValue(`specialOpeningHours.${index}.date`, date)}
                                        removeButton={() => remove(index)}
                                    />
                                    <HoursInput
                                        name='specialOpeningHours'
                                        values={values}
                                        key={index}
                                        objectKey={index}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>
                            )
                        })}
                    </div>
                )}
            </FieldArray>
        </div>
    )
}

SpecialOpeningHoursInput.propTypes = {
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired
}

const DaysInput = ({ values, setFieldValue, label }) => (
    <div>
        <label style={{ marginBottom: '20px', display: 'block' }}>{label}</label>
        {
            Object.keys(values.openingHours).map((key, index) => (
                <HoursInput
                    name='openingHours'
                    values={values}
                    setFieldValue={setFieldValue}
                    key={key}
                    objectKey={key}
                    label={getDayForKey(key)}
                />
            ))
        }
    </div>
)

DaysInput.propTypes = {
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired
}