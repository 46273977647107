import React from 'react';
import './Input.scss';

// import RemoveIcon from './icons/remove.svg';
import RemoveIcon from '../../../assets/icons/remove.svg';

// Formik
import { Field, ErrorMessage } from 'formik'


const Input = ({ label, name, type, required, placeholder, removeButton, ...rest }) => (
    <label className={`input_item ${removeButton ? 'short' : ''}`}>
        {label} {required ? '*' : ''}
        <ErrorMessage name={name} component='div' className='error' />
        <Field type={type || "text"} name={name} placeholder={placeholder} {...rest} />
        {
            removeButton &&
            <button type="button" onClick={removeButton} className='remove-button'>
                <img src={RemoveIcon} alt="fjarlægja" />
            </button>
        }
    </label>
)

export default Input;